import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import LeadsTable from "../../components/leads-table";
import useFetchData from "../../components/use-fetch-data";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdminLayout from "../../components/admin-layout";
import BrokerMultiStepForm from "../../components/broker-multi-step-form";
import AddBrokerSection from "../../components/add-broker-section";
import BrokerDetailsSection from "../../components/broker-details-section";
import withRoleBasedRedirect from "../../components/with-role-based-redirects";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Spinner } from "react-bootstrap";

const AddBrokerPage = () => {
	const [brokerIdNew, setBrokerIdNew] = useState();
	let queryString = "";
	if (typeof window !== "undefined") {
		queryString = window.location.search;
	}
	const urlParams = new URLSearchParams(queryString);
	const brokerId = urlParams.get("brokerId");
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY; // Ensure you're using the right environment variable for your API key

	useEffect(() => {
		setBrokerIdNew(brokerId);
	}, [brokerId]);

	const {
		data: brokerData,
		loading: loadingBroker,
		error: errorBroker,
	} = useFetchData(
		`/.netlify/functions/fetchAirtableBrokerById?id=${brokerId}`,
		apiKey
	);

	// Since loading and error states are handled within each call, we don't need additional state hooks for them

	// You may need to adjust this if your data structure requires it
	const broker = brokerData ? brokerData : null; // Assuming the broker data is an array and you're interested in the first item

	if (loadingBroker) {
		return (
			<AdminLayout>
				<div
					style={{ width: "100%", height: "100%" }}
					className="position-relative"
				>
					<div
						style={{ top: "50vh" }}
						className="position-absolute start-50 translate-middle"
					>
						<Spinner
							style={{ width: "6rem", height: "6rem" }}
							animation="border"
							role="status"
							variant="primary"
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>

					<GatsbySeo title="Broker Details" language="en" noindex nofollow />
				</div>
			</AdminLayout>
		);
	}

	return (
		<AdminLayout>
			<GatsbySeo title="Broker Details" language="en" noindex nofollow />
			<Row className="bg-med-grey">
				<Col>
					<BrokerDetailsSection broker={broker} id={brokerIdNew} />
				</Col>
			</Row>
		</AdminLayout>
	);
};

export default withRoleBasedRedirect(AddBrokerPage, {
	requiredRole: "Super Admin",
});
